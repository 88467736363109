<template>
  <b-card
      class="gridhouse-edit-wrapper"
  >
    <!-- form -->
    <b-form id="gridhouseForm" class="edit-form mt-2">
      <b-row>
        <!--网格仓名称-->
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="网格仓名称"
              label-for="grid_name"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="grid_name"
                size="sm"
                placeholder="请输入名称"
                :readonly="editFlag"
                v-model="gridhouse.grid_name"
            />
          </b-form-group>
        </b-col>
        <!--送货单价-->
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="送货单价"
              label-for="send_price"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="send_price"
                size="sm"
                type="number"
                placeholder="请输入送货单价"
                @blur="fixNumber('send_price')"
                v-model="gridhouse.send_price"
            />
          </b-form-group>
        </b-col>
        <!--团点单价-->
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="团点单价"
              label-for="group_price"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="group_price"
                size="sm"
                type="number"
                placeholder="请输入团点单价"
                @blur="fixNumber('group_price')"
                v-model="gridhouse.group_price"
            />
          </b-form-group>
        </b-col>
        <!--分拣单价-->
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="分拣单价"
              label-for="sort_price"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="sort_price"
                size="sm"
                type="number"
                placeholder="请输入分拣单价"
                @blur="fixNumber('sort_price')"
                v-model="gridhouse.sort_price"
            />
          </b-form-group>
        </b-col>
        <!--运费单价-->
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="运费单价"
              label-for="carriage_price"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="carriage_price"
                size="sm"
                type="number"
                placeholder="请输入运费单价"
                @blur="fixNumber('carriage_price')"
                v-model="gridhouse.carriage_price"
            />
          </b-form-group>
        </b-col>
        <!--月仓储费-->
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="月仓储费"
              label-for="store_price"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="store_price"
                size="sm"
                type="number"
                placeholder="请输入月仓储费"
                @blur="fixNumber('store_price')"
                v-model="gridhouse.store_price"
            />
          </b-form-group>
        </b-col>
        <!--联系人-->
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="联系人"
              label-for="linker"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="linker"
                size="sm"
                placeholder="请输入联系人"
                :readonly="editFlag"
                v-model="gridhouse.linker"
            />
          </b-form-group>
        </b-col>
        <!--联系方式-->
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="联系方式"
              label-for="link_info"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="link_info"
                size="sm"
                placeholder="请输入联系方式"
                :readonly="editFlag"
                v-model="gridhouse.link_info"
            />
          </b-form-group>
        </b-col>
        <!--联系地址-->
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="联系地址"
              label-for="link_address"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-textarea
                id="link_address"
                size="sm"
                placeholder="请输入联系地址"
                :readonly="editFlag"
                v-model="gridhouse.link_address"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import gridhouseStore from './gridhouseStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty} from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      gridhouse: ref({}),
      editFlag: false
    }
  },
  props: {
    gh_id: {
      type: Number,
      default: 0
    },
  },
  methods: {
    getSaveInfo() {
      return this.gridhouse
    },
  },
  setup(props) {
    const toast = useToast()

    // Register module
    if (!store.hasModule('gridhouse')) store.registerModule('gridhouse', gridhouseStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('gridhouse')) store.unregisterModule('gridhouse')
    })

    const edit = function () {
      store.dispatch('gridhouse/edit', {id: props.gh_id}).then(res => {
        this.gridhouse = res.data.data
        if(!this.gridhouse.new){
          this.editFlag = true
        }
      })
    }

    const view = function () {
      store.dispatch('gridhouse/view', {id: this.id}).then(res => {
        this.gridhouse = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      store.dispatch('gridhouse/save', this.gridhouse).then(res => {
        toast.success('数据已保存!')
      })
    }

    const fixNumber = function (type) {
      if (!isEmpty(this.gridhouse[type])) {
        this.gridhouse[type] = Number(this.gridhouse[type]).toFixed(2)
      } else {
        this.gridhouse[type] = ''
      }
      this.$forceUpdate()
    }

    return {
      edit,
      view,
      cancel,
      save,
      fixNumber,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
