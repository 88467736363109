<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  class="mr-1"
                  @click="showEditModal(0)"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">新增网格仓</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--网格仓名称-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="网格仓名称"
                    label-for="grid_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="grid_name"
                      size="sm"
                      v-model="condition.grid_name"
                      placeholder="请输入网格仓名称"
                  />
                </b-form-group>
              </b-col>

              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <!--联系地址-->
        <template #cell(link_address)="data">
          <div :id="`link_address-${data.item.id}`">
            <span v-if="data.item.link_address">
              {{ data.item.link_address.substring(0, 10) }}
              <span v-if="data.item.link_address.length>10">...</span>
            </span>
          </div>
          <b-tooltip :target="`link_address-${data.item.id}`" placement="top">
            {{ data.item.link_address }}
          </b-tooltip>
        </template>

        <!--添加人-->
        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!--添加时间-->
        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <!--更新人-->
        <template #cell(updator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!--更新时间-->
        <template #cell(update_time)="data">
          {{ toTime(data.item.update_time) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="showEditModal(data.item.gh_id)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item @click="delById(data.item.id)">
              <feather-icon icon="Trash2Icon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <!--新增网格仓模态框-->
    <b-modal
        id="editModal"
        ok-title="确认"
        cancel-title="取消"
        @ok="save"
        centered
        size="lg"
        title="新增网格仓"
    >
      <gridhouse-edit :gh_id="gh_id" ref="ghRef"></gridhouse-edit>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import gridhouseUseList from './gridhouseUseList'
import gridhouseStore from './gridhouseStore'
import Ripple from "vue-ripple-directive";
import GridhouseEdit from "@/views/apps/gridhouse/GridhouseEdit";
import {useToast} from "vue-toastification/composition";
import axios from "@/libs/axios";

export default {
  components: {
    GridhouseEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      isShowCard: false,
      gh_id: 0,
    }
  },
  directives: {
    Ripple,
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('gridhouse')) store.registerModule('gridhouse', gridhouseStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('gridhouse')) store.unregisterModule('gridhouse')
    })

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData()
    }

    const showEditModal = function (id) {
      this.gh_id = id
      this.$bvModal.show('editModal')
    }

    const save = function (bvModalEvt) {
      let saveInfo = this.$refs.ghRef.getSaveInfo();
      let flag = true
      if(isEmpty(saveInfo.grid_name)){
        bvModalEvt.preventDefault()
        flag = false
        toast.error('请填写 网格仓名称')
      }
      if(isEmpty(saveInfo.send_price)){
        bvModalEvt.preventDefault()
        flag = false
        toast.error('请填写 送货单价')
      }
      if(isEmpty(saveInfo.group_price)){
        bvModalEvt.preventDefault()
        flag = false
        toast.error('请填写 团点单价')
      }
      if(isEmpty(saveInfo.sort_price)){
        bvModalEvt.preventDefault()
        flag = false
        toast.error('请填写 分拣单价')
      }
      if(isEmpty(saveInfo.carriage_price)){
        bvModalEvt.preventDefault()
        flag = false
        toast.error('请填写 运费单价')
      }
      if(isEmpty(saveInfo.store_price)){
        bvModalEvt.preventDefault()
        flag = false
        toast.error('请填写 月仓储费')
      }
      if(isEmpty(saveInfo.linker)){
        bvModalEvt.preventDefault()
        flag = false
        toast.error('请填写 联系人')
      }
      if(isEmpty(saveInfo.link_info)){
        bvModalEvt.preventDefault()
        flag = false
        toast.error('请填写 联系方式')
      }
      if(isEmpty(saveInfo.link_address)){
        bvModalEvt.preventDefault()
        flag = false
        toast.error('请填写 联系地址')
      }
      if(saveInfo.send_price < 0){
        bvModalEvt.preventDefault()
        flag = false
        toast.error('送货单价 不能为负')
      }
      if(saveInfo.group_price < 0){
        bvModalEvt.preventDefault()
        flag = false
        toast.error('团点单价 不能为负')
      }
      if(saveInfo.sort_price < 0){
        bvModalEvt.preventDefault()
        flag = false
        toast.error('分拣单价 不能为负')
      }
      if(saveInfo.carriage_price < 0){
        bvModalEvt.preventDefault()
        flag = false
        toast.error('运费单价 不能为负')
      }
      if(saveInfo.store_price < 0){
        bvModalEvt.preventDefault()
        flag = false
        toast.error('月仓储费 不能为负')
      }
      if(!flag) return
      store.dispatch('gridhouse/save', saveInfo).then(res => {
        if (res.data.code == 0) {
          toast.success("提交成功")
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const delById = function (id) {
      this.$swal({
        title: "确定删除吗",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          axios.post('api/gridhouse/state', {id: id, state: 0}).then(res => {
            if (res.data.code == 0) {
              //弹窗
              this.$swal({
                icon: 'success',
                title: '已删除!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              //重新加载表格
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = gridhouseUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      advanced_search,
      searchByCondition,
      resetCondition,
      condition,
      showEditModal,
      save,
      delById,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
